<style lang="scss">
    .app-header{
        width: 100vw;

        .dark-mode-switch{
            i{
                color: black;
            }

            &.dark i{
                color: white;
            }

            .v-messages{
                display: none;
            }

            .v-input__slot{
                margin-bottom: 0;
            }
        }

        &__title p{
            font-size: 15pt;
        }
    }
</style>

<template>
  <v-app-bar
      :class="fullscreen ? 'animate__slideOutUp' : 'animate__slideInDown'"
      class="app-toolbar app-header theme-background animate__animated animate__faster"
      fixed
      dense
      height="64px"
      max-height="64px"
  >
    <div class="d-flex align-center app-header__title" @click="backToDashboard">
      <p class="ml-4 mb-0 primary-dark">
        Meditation
      </p>
    </div>
    <v-spacer v-if="isExperienceScene" />
    <h5 v-if="isExperienceScene" class="title-color font-weight-regular">
      Touch scene for full immersion
    </h5>
    <v-spacer/>
    <section>
      <v-icon class="me-4" color="#410067" @click="backToDashboard">
        fas fa-home
      </v-icon>
      <v-icon v-if="isAndroid" class="mx-4" color="#410067" @click="closeApp">
        fas fa-times
      </v-icon>
    </section>
  </v-app-bar>
</template>

<script>
  import { mapState } from 'vuex';
  import {Howler} from 'howler';

  export default {
    name: 'App-Header',
    props: [''],
    components: {},
    computed: {
      ...mapState({
        fullscreen: state => state.fullscreenMode
      }),
      darkMode: {
        get () {
          return this.$store.state.darkMode
        },
        set (value) {
          this.$store.commit('SET_DARK_MODE', value)
        }
      },
      isAndroid() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1;
      },
      isExperienceScene() {
        return this.$route.name === 'experience-scene';
      }
    },
    data() {
      return {
        dark: false
      };
    },
    mounted() {

    },
    methods: {
        backToDashboard() {
          console.log('called back');
          this.$router.push({name: 'dashboard'});
        },
        closeApp() {
          Howler.unload(); // stop all music
          // eslint-disable-next-line no-undef
          Android.quit();
        },
    },
  };
</script>
